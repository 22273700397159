:root {
  --switches-bg-color: black;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: black;
}

/* resize font-size on html and body level. html is required for widths based on rem */
@media screen and (min-width: 1024px) {
  html,
  body {
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px) {
  html,
  body {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 12px;
  }
}

/* p - decorative, not required */
p {
  margin-top: 2rem;
  font-size: 0.75rem;
  text-align: center;
}

/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switches-container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  line-height: 3rem;
  margin-left: auto;
  margin-right: auto;
  height: 2rem;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: var(--switches-label-color);
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  background: var(--switch-bg-color);
  height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  height: 2rem;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}
