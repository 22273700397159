@import url("../rewards/pay.css");
@import url("../proxima.css");
@import url("checkbox.css");
@import url("toggle.css");
@import url("../fa.css");

body {
  margin: 0;
  padding: 0;
}

input,
textarea {
  all: unset;
  outline: 0;
  border-radius: 5px;
  width: 90vw;
  border: 1px solid #d2d2e7;
  height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  text-transform: none;
}

select {
  outline: 0;
  border-radius: 5px;
  width: 90vw;
  border: 1px solid #d2d2e7;
  height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  text-transform: none;
}

textarea {
  height: 140px;
  white-space: break-spaces;
}

input:focus,
textarea:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 32px 0;
}

.mb-3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#getReport {
  all: unset;
  background: #0d6efd;
  border-radius: 6px;
  padding: 14px 20px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  user-select: none;
}

#getReport:not(:disabled):hover {
  background: #0a58ca;
}

#getReport:disabled {
  cursor: default;
}

.audit-tag {
  font-size: 26px;
  color: black;
  font-weight: 900;
}

a {
  text-transform: none;
}

a:hover {
  color: black;
}

#searchInput {
  margin-bottom: 20px;
  width: 100%;
}

#resultTable {
  border-spacing: 0.8em;
}

.resultContainer tbody:before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}

.resultContainer {
  width: 87vw;
  border-spacing: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  box-shadow: 0px 0px 6px #0089ff59;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

td {
  text-align: center;
}

span {
  color: white;
}

.btn-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.cache-title {
  font-size: 15px;
  font-weight: 900;
  color: #0072ff;
  user-select: none;
}

.btn-loader {
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#report-loader {
  display: none;
}

#amountHeader {
  position: relative;
  cursor: pointer;
}

#sortArrow {
  color: black;
  font-size: 16px;
  margin-left: 5px;
  /* Adjust the spacing between the text and arrows */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
